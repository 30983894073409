<template>
	<!-- 开通票据 -->
	<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
		<div class="form-Box">
			<div class="right-box padding-lr-20 bg-fff" style="padding-top: 25px;">
				<div class="font-size20 font-weight700 margin-b-20">开通票据钱包</div>
			</div>
			<div class="right-box padding-tb-25 padding-lr-20 bg-fff">
				<div class="richtext-box margin-b-10">
					<div v-if="content != null" v-html="content"></div>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="145px" class="demo-ruleForm">
					<el-form-item label="" id="setform" prop="radio">
						<div style="line-height: 20px !important;">
							<el-checkbox-group v-model="ruleForm.radio">
								<el-checkbox label="我已阅读并同意"></el-checkbox>
							</el-checkbox-group>
							<span v-for="(item,index) in zftAgreement" :key='index' style="display: inline-block;line-height: 20px;">
								<span style="cursor: pointer;" class="color-theme font-size12 lh21" @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
							</span>
						</div>
					</el-form-item>
					<el-form-item label-width="190px">
						<el-button class="background-color-theme" style="cursor: pointer;" @click="nextStepClick">立即开通</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="bg-box"></div>
		</div>
		<agreementDialog :fTitle="fTitle" :iContents='iContent' :downLoadIds="fAgreementTypeID" ref="areDialog"></agreementDialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import IdentityCodeValid from '@/utils/checkIdent'
	import agreementDialog from '@/components/agreementDialog';
	import BranchSelection from './BranchSelection';
	import RichTextStyle from "@/components/richtext/RichTextStyle";
	export default {
		data() {
			var checkMoney = (rule, value, callback) => {
				if (!/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
					callback(new Error("金额必须为数字值且最多保留两位小数"));
				} else {
					callback();
				}
			};
			//   身份证号码验证规则
			let checkRegNo = (rule, value, callback) => {
				if (!IdentityCodeValid(value)) {
					callback(new Error('请输入正确的身份证'))
				} else {
					callback()
				}
			}
			// 验证手机号的校验规则
			let checkMobile = (rule, value, callback) => {
				// 验证手机号的正则表达式
				const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
				if (value == '') {
					callback(new Error("手机号码不能为空"));
				} else if (regMobile.test(value)) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("请输入正确的手机号"))
			}

			return {
				fTitle: '', //富文本标题
				content: '', //富文本内容
				rule: [], //富文本规则
				countDown: 10,
				validStatue: false,
				subStatue: false,
				BranchSelection: false,
				zftAgreement: [],
				dialogFormVisible: false,
				formLabelWidth: "80px",
				dialogruleForm: {
					money: null,
				},
				dialogrules: {
					money: [{
						validator: checkMoney,
						trigger: "blur"
					}],
				},
				loading: true,
				title: '',
				type: '',
				fAccount: '',
				changeAccount: false,
				userinfo: {},
				bankList: [], //开户行
				certificateType: [],
				verificationCodeText: "获取验证码",
				ss: "",
				fLegalPerson: '',
				fBankCode: '',
				agreementData: [], //店铺协议
				ruleForm: {
					radio: false,
				},
				rules: {
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意开通票据钱包协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				},
				obj: null,
				noticeShow: false,
				iContent: '',
				fkhmC1List: [],
				fkhmC1ListCopy: [],
				fBranchID: '',
				promiseTimer: '',
				fAgreementHistID : '',
				noticeShow: false,
				fAgreementTypeID : '',
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(["getUserInfo"]),
		},
		components: {
			agreementDialog,
			BranchSelection,
			RichTextStyle
		},
		created() {
			// this.ruleForm = {}
			this.title = this.$route.query.title;
			this.type = this.$route.query.type;
			this.getInfo();
		},
		mounted() {
			this.getAgreementHistory();
		},
		methods: {
			showAgreeDia(a) {
			
				this.noticeShow = true;
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			//获取协议内容
			getAgreementHistory() {
				this.ApiRequestPost('api/mall/ebbase/agreement-history/get-list-by-b2c-ebcn')
					.then(
						res => {
							this.loading = false
							console.log('获取协议id', res);
							res.obj.items.forEach((item, index) => {
								if (index == 0) {
									res.obj.items[index].checked = true;
									this.fTitle = res.obj.items[index].fTitle;
									this.fAgreementHistID = res.obj.items[index].fAgreementHistID;
								} else {
									res.obj.items[index].checked = false;
								}
							});
							this.zftAgreement = res.obj.items
							this.agreementData = res.obj.items;
							this.getAgreementHistorys();
						},
						error => {}
					);
			},
			//切换协议
			agreementTabClick(obj) {
				clearInterval(this.promiseTimer)
				this.countDown = 10;
				this.agreementData.forEach((item, index) => {
					if (obj.fAgreementHistID == item.fAgreementHistID) {
						this.fAgreementHistID = obj.fAgreementHistID;
						this.agreementData[index].checked == true;
					} else {
						this.agreementData[index].checked == false;
					}
				});
				this.getAgreementHistorys();
			},
			//获取协议内容
			getAgreementHistorys() {
				this.ApiRequestPost('api/mall/ebbase/agreement-history/get', {
					FAgreementHistID: this.fAgreementHistID
				})
					.then(
						res => {
							this.content = res.obj.fContent
							this.fTitle = res.obj.fTitle
							this.fAgreementTypeID = res.obj.fAgreementTypeID
							this.loading = false
							let _this = this;
							this.promiseTimer = setInterval(function() {
								_this.countDown = _this.showtime()
							}, 1000);
						},
						error => {}
					);
			},
			showtime() {
				let second = 10;
				if (this.countDown > 0) {
					this.countDown -= 1;
				} else if (this.countDown == 0) {
					clearInterval(this.promiseTimer)
				}
				return this.countDown; //返回倒计时的字符串
			},
			//协议同意并继续
			nextStepClick() {
				this.$refs['ruleForm'].validate((valid) => {
					if(valid){
						this.ApiRequestPost(
							"api/mall/ebbalance/customer-acceptance/open-acceptance-wallet", {}
						).then(
							(result) => {
								this.$message(result.message)
								this.$router.replace({
									path: '/businessme/billWallet'
								})
							},
							(rej) => {
						
							}
						);
					}
				})
				
				
			},
			//   获取用户信息
			getInfo() {
				this.ApiRequestPost(
					"/api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full", {}
				).then(
					(result) => {
						console.log(result, "00000000000");
						this.userinfo = result.obj
						// this.getBankList();
					},
					(rej) => {
						// this.getBankList();
					}
				);
			},
		},
	};
</script>

<style lang="scss" scoped="scoped">
	.button-Branch {
		position: absolute;
		z-index: 2;
		top: 5px;
		left: 310px;
		width: 70px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	::v-deep .el-dialog {
		width: 500px;
	}

	//协议复选框样式
	::v-deep .el-checkbox-group {
		width: fit-content !important;
		display: inline !important;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.icon-biaodan {
		color: #1c9dff;
	}

	/deep/ .el-form-item__label {
		padding-right: 19px;
	}

	/deep/ .el-input__inner {
		width: 380px;
		height: 32px;
		line-height: 32px;
		padding: 0 10px;
		border: 1px solid #ccc !important;
	}

	.title {
		height: 20px;
		line-height: 20px;
		margin: 55px auto 16px;
		margin-left: 410px;
	}

	.contBox {
		overflow: hidden;
	}

	.demo-ruleForm {
		// width: 462px;
		margin: 0px auto;
		margin-left: 180px;
		position: relative;
		z-index: 1;
	}

	::v-deep .bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}
	
	.btn-box {
		width: 235px;
		height: 45px;
		line-height: 45px;
		margin : auto;
	}
	
	.richtext-box {
		min-height: 575px;
		max-height: 575px;
		overflow-y: auto;
	}
	
	.right-box {
		width: calc(1100px - 200px);
	}
	
	.left-box {
		height: 748px;
		width: 190px;
	}
	
	.left-title {
		height: 36px;
		line-height: 36px;
	}
	
	.left-content {
		height: 41px;
		line-height: 41px;
	}
	
	.bg-DDEBFF {
		background-color: #DDEBFF;
	}
	
	.demo-ruleForm {
		// width: 462px;
		margin: 0px auto;
		margin-left: 180px;
		position: relative;
		z-index: 1;
	}
	
</style>